import { cookiesConsent } from './helpers/cookies_consent';

// ! Cookies consent
const cookiesPopup = document.querySelector('#cookie');
const allowBtnCookies = document.querySelector('.cookie_consent_btn');
const cookiesName = 'help-dc';

if (cookiesPopup && allowBtnCookies && cookiesName) {
    cookiesConsent(cookiesPopup, allowBtnCookies, cookiesName);
}
const buttonBurger = document.querySelector(".q10__header__top__burger_menu");
const burgerMenu = document.querySelector(".q10__header__popup");

buttonBurger.addEventListener("click", (e) => {
    if(!burgerMenu.classList.contains("active")){
        burgerMenu.classList.add("active")
    }else{
        burgerMenu.classList.remove("active");
    }
});

addEventListener("resize", (event) => {
    var MediaMobile = window.matchMedia("(max-width: 768px)")
    if (MediaMobile.matches){
        burgerMenu.classList.add("active")
        burgerMenu.classList.remove("active");
    } else {
        burgerMenu.classList.remove("active");
    }
});

function scrollTop() {
    const scrollTop = document.querySelector(".scrolltop");
    if (this.scrollY >= 150) scrollTop.classList.add("show-scroll");
    else scrollTop.classList.remove("show-scroll");
}
window.addEventListener("scroll", scrollTop);

// Stergere border 1px pentru primul "orizontal_line", din blocul de cod aferent
const postSectionBlock = document.querySelectorAll('.q10__post__section__block')
const contentTitleSectionBlock = document.querySelectorAll('.q10__content__title_section__block')

if(postSectionBlock[0]){
    postSectionBlock[0].children[0].style = "border: 0px;"
}
if(contentTitleSectionBlock[0]){
    contentTitleSectionBlock[0].children[0].style = "border: 0px;"
}

const popUpText = document.querySelector('.popUpText');

if(popUpText && popUpText.hasChildNodes()){
const nodeText = popUpText.childNodes;
var createP = document.createElement('p')
createP.append(nodeText)


const accordionItemHeaders = document.querySelectorAll(".q10__content__title_section__block__title.to-show");

accordionItemHeaders.forEach(accordionItemHeader => {
    accordionItemHeader.addEventListener("click", event => {

    accordionItemHeader.classList.toggle("unfolding");
    const accordionItemBody = accordionItemHeader.nextElementSibling;
    if(accordionItemHeader.classList.contains("unfolding")) {
        accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight  + "px";

        accordionItemHeader.children[0].children[0].innerHTML='<span class="circle">-</span>';
      }
      else {
        accordionItemBody.style.maxHeight = 0;
        accordionItemHeader.children[0].children[0].innerHTML='<span class="circle">+</span>';
      }
  });
});

}

const blockArrow = document.querySelectorAll('.q10__block__boxes__content__view__items__item__link');

for (var i = 0; i< blockArrow.length; i++){
    blockArrow[i].addEventListener('mouseenter', function() {
       const arrowIconQuery=  this.querySelector('.icon');
       arrowIconQuery.classList.add("addAnimationToArrows");
    })

    blockArrow[i].addEventListener('mouseleave', function() {
        const arrowIconQuery=  this.querySelector('.icon');
        arrowIconQuery.classList.remove("addAnimationToArrows")
    })
}

